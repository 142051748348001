


import React from 'react';
import logo from '../images/logos/logo-app.png'


function Header(){
    return(
          
        <header className="header header_style_01">
        <nav className="navbar header-nav navbar-expand-lg">
            <div className="container">
                <a className="navbar-brand" href="#">İsteBiKurye</a> 
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarApp" aria-controls="navbarApp" aria-expanded="false" aria-label="Toggle navigation">
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarApp">
                    <ul className="navbar-nav">
                        <li><a className="nav-link active" href="#home">Anasayfa</a></li>
                        <li><a className="nav-link" href="#features">Özellikler</a></li>
                        <li><a className="nav-link" href="#screens">Vitrin</a></li>
                      
                        <li><a className="nav-link" href="#download">İndir</a></li>
                        <li><a className="nav-link" href="#support">İletişim</a></li>
                    </ul>
                </div>
            </div>
        </nav>
      </header>
    )
}

export default Header













