import React from 'react';

function TopHeadBar(){
    return(
        <div className="top-bar">
		<div className="container-fluid">
			<div className="row">
				<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
					<div className="left-soi">
						<ul>
							<li className="social-links"><a href="https://apps.apple.com/us/app/aninda-bi-kurye/id1585649747"><i className="fa fa-apple"></i></a></li>
							<li className="social-links"><a href="https://play.google.com/store/apps/details?id=com.musterianinda.apps"><i className="fa fa-android"></i></a></li>
						</ul>
					</div>
				</div>
				<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
					<div className="right-btn">
						<a href="https://wa.me/905495832320" className="btn-radius btn-brd">Canlı Destek</a>
					</div>
				</div>
			</div>
		</div>
	</div>
    )
}

export default TopHeadBar