import React, { useRef, useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import Modal from 'react-modal';


Modal.setAppElement('#map');


function Package() {

  const ref = useRef();
  const [alinacakName, setAlinacakName] = useState("");
  const [gidecegiName, setGidecegiName] = useState("");
  const [distance, setDistance] = useState(0);
  const [duration, setDuration] = useState(0);
  const [latitudeAlinacak, setLatitudeAlinacak] = useState(0);
  const [longitudeAlinacak, setLongitudeAlinacak] = useState(0);

  const [latitudeGidegegi, setLatitudeGidecegi] = useState(0);
  const [longitudeGidegegi, setLongitudeGidegegi] = useState(0);
  const [error, setError] = useState(false)

  const [modalIsOpen, setIsOpen] = useState(false);



  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      
    },
    overlay: {zIndex: 1000}
  };



  const getAlinacakName = (value) => {
    setAlinacakName(value.value.description)
    setError(false)
    console.log(value.value.description)
    getLocationAlinacak(value.value.description, value.value.place_id)
  }



  const openModal = () => {
    setIsOpen(true)
  }


  const closeModal = () => {
    setIsOpen(false)
  }

  const getGidecegiName = (value) => {
    setGidecegiName(value.value.description)
    setError(false)
    console.log(value.value.description)
    //console.log(value.value.place_id)
    getLocationGidegegi(value.value.description, value.value.place_id)

  }

  let latitudeOrigin = 0;
  let longitudeOrigin = 0;
  let Tot = 0;

  let gidecegilatitude = 0;
  let gidecegilongitude = 0;

  const getLocationAlinacak = (address, placeId) => {

    const map = new window.google.maps.Map(document.getElementById("map"))
    var request = {
      placeId: placeId,
      fields: ["formatted_address", 'geometry']
    };

    const service = new window.google.maps.places.PlacesService(map);
    service.getDetails(request, (place, status) => {
      if (status == window.google.maps.places.PlacesServiceStatus.OK) {
        setLatitudeAlinacak(place.geometry.location.lat())
        setLongitudeAlinacak(place.geometry.location.lng())
        console.log(place.geometry.location.lat())
        console.log(place.geometry.location.lng())


        var services = new window.google.maps.DistanceMatrixService();
        services.getDistanceMatrix(
          {
            origins: [{ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() }, alinacakName],
            destinations: [gidecegiName, { lat: latitudeGidegegi, lng: longitudeGidegegi }],
            travelMode: 'DRIVING',
            // transitOptions: TransitOptions,
            // drivingOptions: DrivingOptions,
            // unitSystem: UnitSystem,
            // avoidHighways: Boolean,
            // avoidTolls: Boolean,
          }, (response, status) => {

            if (response.rows[0].elements[1].status === "OK") {
              setDuration(response.rows[0].elements[1].duration.text)
              setDistance(((response.rows[0].elements[1].distance.value) / 1000))

            }

          });
      }
    });

  }



  const getLocationGidegegi = (address, placeId) => {

    const map = new window.google.maps.Map(document.getElementById("map"))

    var request = {
      placeId: placeId,
      fields: ["formatted_address", 'geometry']
    };

    const service = new window.google.maps.places.PlacesService(map);
    service.getDetails(request, (place, status) => {
      if (status == window.google.maps.places.PlacesServiceStatus.OK) {
        setLatitudeGidecegi(place.geometry.location.lat())
        setLongitudeGidegegi(place.geometry.location.lng())
        // console.log(place.geometry.location.lat())
        // console.log(place.geometry.location.lng())

        var services = new window.google.maps.DistanceMatrixService();
        services.getDistanceMatrix(
          {
            origins: [{ lat: latitudeAlinacak, lng: longitudeAlinacak }, alinacakName],
            destinations: [gidecegiName, { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() }],
            travelMode: 'DRIVING',
            // transitOptions: TransitOptions,
            // drivingOptions: DrivingOptions,
            // unitSystem: UnitSystem,
            // avoidHighways: Boolean,
            // avoidTolls: Boolean,
          }, (response, status) => {
            if (response.rows[0].elements[1].status === "OK") {
              setDuration(response.rows[0].elements[1].duration.text)
              setDistance(((response.rows[0].elements[1].distance.value) / 1000))
              console.log(response.rows[0].elements[1].distance.text)
            }
          });
      }
    });
  }


  const onayla = (e) => {
    e.preventDefault();
    console.log(e)
    if (distance === 0 && alinacakName === "" && gidecegiName === "") {
      setError(true)
    }
    else {
      openModal()
    }

  }

  Tot = ((distance) <= 0 ? 0 : (distance) > 0 && (distance) <= 5 ? 180 : (distance) > 5 && (distance) <= 11 ? 250 : (distance) > 11 && (distance) <= 18 ? 300 : ((distance).toFixed(2) * 14) + 10)
 


  return (
    <div className="service-form">
      <div className="testbox">
        <form action="/">
          <div className="banner">
          </div>
          <div className="item">

            <div className="name-item">
              <label>Alınacak Adress</label>
            </div>
            <GooglePlacesAutocomplete
              apiKey='AIzaSyBXTdBLkISA2tGKCazC_PBWblWEsed9ir4'
              apiOptions={{ language: 'tr' }}
              autocompletionRequest={{
                componentRestrictions: {
                  country: ['tr'],
                }
              }}
              selectProps={{
                alinacakName,
                onChange: (alinacakName) => getAlinacakName(alinacakName),
                placeholder: "Alınacak Adress..."
              }}
              ref={ref}
            />

            <div className="name-item">
              <label>Verilecek Adress</label>
            </div>

            <GooglePlacesAutocomplete
              apiKey='AIzaSyBXTdBLkISA2tGKCazC_PBWblWEsed9ir4'
              apiOptions={{ language: 'tr' }}
              autocompletionRequest={{
                componentRestrictions: {
                  country: ['tr'],
                }
              }}
              selectProps={{
                gidecegiName,
                onChange: (gidecegiName) => getGidecegiName(gidecegiName),
                placeholder: "Gideceği Adress..."
              }}
              ref={ref}
              autoFocus={true}
              returnKeyType={'default'}
            />


            <div className="name-item flex-mobile">
              <label>Toplam Mesafe :</label>   <label>{(distance).toFixed(2)} KM</label>
            </div>


            <div className="name-item flex-mobile">
              <label>Hizmet Bedeli  :</label>   <label> 10 Tl</label>
            </div>


            <div className="name-item flex-mobile">
              <label>Km Başı Fiyatlandırma :</label>   <label>14 Tl</label>
            </div>

            <div className="name-item flex-mobile">
              <label>Ödenecek Toplam :</label>   <label>{Tot.toFixed(0)} Tl</label>
            </div>

            <div className="name-item flex-mobile">
              <label>Sure :</label>   <label> {duration}</label>
            </div>
            {error ?
              <div className="name-item flex-mobile">
                <label style={{ color: "red" }}>Lütfen formu doldurun </label>
              </div>
              : null
            }
          </div>

          <div className="btn-block">
            <button onClick={onayla} >
              Onayla
            </button>
          </div>
        </form>
      </div>

      <Modal
        shouldCloseOnOverlayClick
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        
      >
        <div style={{ display: "flex", alignItems: "center" }} >
          <h2 style={{ color: "orange", textAlign: "center", flex: 2 }}>İSTEBIKURYE</h2>
          <button onClick={closeModal}>X</button>
        </div>

        <div className="big-tagline">
          <div style={{textAlign:"center", marginBottom:"20px"}}>Bizim uygulama indir</div>
          <a data-scroll href="https://play.google.com/store/apps/details?id=com.musterianinda.apps" className="btn btn-light btn-radius btn-brd"><i className="fa fa-play" aria-hidden="true"></i> GooglePlay </a>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

          <a data-scroll href="https://apps.apple.com/tr/app/i-stebi-kurye-hizli-tesli-mat/id1585649747" className="btn btn-light btn-radius btn-brd"><i className="fa fa-apple" aria-hidden="true"></i> App Store</a>
        </div>


      </Modal>

    </div>
  )
}


export default Package