import React from 'react';
import form_bg from '../../uploads/form_bg.jpg';





function ContactForm() {

    return (
        <div id="support" class="section" style={{
            background: `url(${form_bg})`,
        }}>
            <div className="parent-contact">

                <div class="container">
                    <div class="section-title text-center">
                        <h3>Bize Mesaj Bırakın</h3>
                        <div class="info-box" data-toggle="tooltip" data-placement="right" title="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.">
                            <i class="fa fa-question-circle" aria-hidden="true"></i>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="contact_form">
                                <div id="message"></div>
                                <form id="contactform" class="row" action="contact.php" name="contactform" method="post">
                                    <fieldset class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <input type="text" name="first_name" id="first_name" class="form-control" placeholder="İsim" />
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <input type="text" name="last_name" id="last_name" class="form-control" placeholder="Soyisim" />
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <input type="email" name="email" id="email" class="form-control" placeholder="Mail adresiniz" />
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <input type="text" name="phone" id="phone" class="form-control" placeholder="Telefon Numaranız" />
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <textarea class="form-control" name="comments" id="comments" rows="6" placeholder="Bir mesaj yazın.." />
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 text-center">
                                            <button class="btn btn-light btn-radius btn-brd grd1 btn-block">Gönder</button>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ContactForm