import logo from './logo.svg';
import './App.css';

import TopHeadBar from './components/bar'
import Header from './components/header'
import Banner from './components/banner'
import AppFeatures from './components/appFeatures/appFeatures';
import HowTo from  './components/howTo/howTo.js'
import ScreenShots from  './components/screenShots/screenShots.js'
import DownloadApp from './components/downloadApp/downloadApp';
import Footer from './components/footer/footer'
import ContactForm from './components/contactForm/contactForm';


function App() {
  return (
    <div className="App">
      <TopHeadBar />
      <Header/>
      <Banner />
      <HowTo/>
      <AppFeatures/>
      <DownloadApp/>
      <ScreenShots />
      <ContactForm/>
      <Footer /> 
    </div>
  );
}

export default App;
