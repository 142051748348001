

import ss_1 from '../../uploads/ss-aninda-bi-kuriye (1).jpeg';
import ss_2 from '../../uploads/ss-aninda-bi-kuriye (2).jpeg';
import ss_3 from '../../uploads/ss-aninda-bi-kuriye (3).jpeg';
import ss_4 from '../../uploads/ss-aninda-bi-kuriye (4).jpeg';
import ss_5 from '../../uploads/ss-aninda-bi-kuriye (5).jpeg';

import iphones from '../../uploads/iphones.png';

import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
function ScreenShots() {

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };


  return (
    <div id="screens" style={{ width: "100%", marginBottom: "50px" }}>
      <div className="carousel_parent" style={{ margin: "0 auto", marginBottom: "50px" }}>
        <Carousel responsive={responsive}>
          <div>
            <img src={ss_1} className="carousel_image" />
          </div>
          <div>
            <img src={ss_2} className="carousel_image" />
          </div>
          <div>
            <img src={ss_3} className="carousel_image" />
          </div>
          <div>
            <img src={ss_4} className="carousel_image" />
          </div>
          <div>
            <img src={ss_5} className="carousel_image" />
          </div>

        </Carousel>
      </div>
    </div>
  );
};


export default ScreenShots


