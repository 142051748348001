import React from 'react';
import iphones from '../../uploads/motor-aninda.png';
import backgroundBanner from '../../uploads/ben_img.jpg'


function DownloadApp() {

    return (
        <div id="download" class="parallax section noover" style={{
            background: `url(${backgroundBanner})`,
        }}>
            <div class="container">
                <div class="row text-center">
                    <div class="col-md-7 col-sm-12">
                        <div class="customwidget text-left">
                            <h1>
                                İsteBiKurye</h1>
                            <h3 style={{ color: "white" }}> ŞIMDI İNDİRİN !!!</h3>                        <p>

                                Uzman kadrosuyla, Moto Kurye ve dağıtım hizmetini yüksek kalite anlayışıyla bütünleştirerek hızlı,
                                güvenli ve doğru evrak taşımacılığını mesleği olarak benimsemiştir. Şirket içi eğitim ve bilgi aktarımı
                                ile kuryelerimiz sizlere, özenle seçilmiş, temiz kıyafetleri içerisinde, saygın iletişim yöntemleri ve son
                                model araçları ile hizmet sunmaktadırlar. Hizmetin tüm unsurlarını kusursuzca yerine getirmek için ve toplam
                                kalite anlayışından hareketle, İstanbul içi tüm kurye ihtiyaçlarınızı karşılamaya yönelik çalışmalarımızla
                                hizmetinizdeyiz.
                            </p>
                            <ul class="list-inline">
                                <li class="list-inline-item"><i class="flaticon-apple"></i> Appstore'da Mevcut</li>
                                <li class="list-inline-item"><i class="flaticon-android"></i> Playstore'da Mevcut</li>
                                -                        </ul>
                            <div className="big-tagline">

                                <a data-scroll href="https://play.google.com/store/apps/details?id=com.musterianinda.apps" target='_blank' className="btn btn-light btn-radius btn-brd"><i className="fa fa-play" aria-hidden="true"></i> GooglePlay </a>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <a data-scroll href="https://apps.apple.com/tr/app/i-stebi-kurye-hizli-tesli-mat/id1585649747" target='_blank' className="btn btn-light btn-radius btn-brd"><i className="fa fa-apple" aria-hidden="true"></i> App Store</a>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-6 iphones hidden-sm">
                        <div class="text-center move image-center hidden-sm hidden-xs">
                            <img src={iphones} alt="" class="img-fluid wow fadeInUp right-motor" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DownloadApp