import React from 'react';
import backgroundBanner from '../uploads/bg-img.jpg';
import app_iphone_02 from '../uploads/app_iphone_02.png';
import app_iphone_01 from '../uploads/app_iphone_01.png'
import Deliver from "./deliver/deliver"
import Package from "./package/package"
import { useState } from 'react';



function Banner() {

    const [paketForm, setPaketForm ] = useState(false) 
    const [deliverForm, setdeliverForm ] = useState(true) 


    const showPaketForm = ()=>{
        setPaketForm(true)
        setdeliverForm(false)
    }

    


    const showDeliverForm = ()=>{
        setdeliverForm(true)
       // setPaketForm(false)

    }
  

    return (
        <div id="home" className="parallax first-section" style={{
            background: `url(${backgroundBanner})`,
        }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div className="big-tagline">
                            <div className=""> 
                             {/* <a onClick={showPaketForm} data-scroll  className="btn btn-light btn-radius btn-brd" style={{backgroundColor: paketForm ? "#c62828" : "rgb(255 255 235 / 29%)"}}><i className="fa fa-play" aria-hidden="true"></i> Motor Kurye </a> */}
                             <a  onClick={showDeliverForm}  data-scroll  className="btn btn-light btn-radius btn-brd"  style={{backgroundColor: deliverForm ? "#c62828" : "rgb(255 255 235 / 29%)"}}><i className="fa fa-play" aria-hidden="true"></i> Acil ve hızlı motor kurye</a>
                            </div>
                           {deliverForm ? <Deliver /> : null }
                           {/* {paketForm ? <Package /> : null } */}
                        </div>
                    </div>

                    <div className="app_iphone_01 wow slideInUp hidden-xs hidden-sm" data-wow-duration="1s" data-wow-delay="0.2s">
                        <img src={app_iphone_01} alt="" className="img-fluid" />
                    </div>
                   
                </div>
            </div>
        </div>
    )
}

export default Banner