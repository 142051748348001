

import React from 'react';


function HowTo() {
    return (

        <div className="how-its-work clearfix">
            <div className="hc colon1">
                <h2>1</h2>
                <h3>Moto Kurye</h3>
                <p className="lead">Çıkış ve varış adreslerini girerek gönderi fiyatını anında görüntüleyebilirsiniz. Kurye talebinizi oluşturduğunuzda çıkış noktasına en yakın kurye 30 dakika içinde kapınızda olur</p>
            </div>

            <div className="hc colon2">
                <h2>2</h2>
                <h3>Zamanında Teslimat</h3>
                <p className="lead">Kurye sektörünün lideri olaran firmamız, Anında bi kurye hizmetinde de profesyonel bir ekibe sahip olarak hizmet verir. Gönderilerinizin teslim alım zamanı ile teslimatı arasındaki zaman en fazla 60-120 dakika olarak hesaplanır.</p>
            </div>

            <div className="hc colon3">
                <h2>3</h2>
                <h3>Motosikletli Kurye</h3>
                <p className="lead">Sahadaki işleyişi hızlandıracak tecrübe sahibi A2 ehliyet ve iş güvenliği sertifikalı kurye kadromuz.</p>
            </div>

            <div className="hc colon4">
                <h2>4</h2>
                <h3>7/24 Canlı Destek!</h3>
                <p className="lead">
                    Hızlı ve Güvenli Teslimat
                    7/24 Hizmetinizdeyiz
                    İstanbul tüm ilçelerinde aktif
                </p>
            </div>
        </div>
    )
}

export default HowTo




