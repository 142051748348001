
import React from 'react';
import carousel_01 from '../../uploads/carousel_01.jpg';
import carousel_02 from '../../uploads/carousel_02.jpg';
import carousel_03 from '../../uploads/carousel_03.jpg';
import carousel_04 from '../../uploads/carousel_04.jpg';




function AppFeatures(){

    return(

        <div id="features" className="section wb">
        <div className="container">
            <div className="section-title text-center">
                <h3>Uygulama Özellikleri</h3>
                <p className="lead">Lorem Ipsum dolroin gravida nibh vel velit auctor aliquet. Aenean sollicitu
                din, lorem quis bibendum auctor nisi elit consequat ipsum, nec sagittis sem!</p>
            </div>
    
            <div id="default" className="row clearfix zenith_slider">
                  
                 
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 left-row">
                    <ul className="features-left">
                        <li className="highlight lhgh" data-index="0">
                         <i className="flaticon-pantone effect-1"></i>	
                            <div className="fl-inner">
                                <h4>İsteBiKurye</h4>
                                <p>Günün her anında bi kurye hizmetine ihtiyaç duyabilirsiniz. Gönderilerinizi güven içinde alıcısına ulaştırmak isteyebilirsiniz.</p>
                            </div>
                        </li>
    
                        <li className="highlight lhgh" data-index="1">
                         <i className="flaticon-pantone effect-1"></i>	
                            <div className="fl-inner">
                                <h4>Şehrin İçi Dağıtım</h4>
                                <p>İsteBiKurye hizmeti de bu noktada, metropol şehrimizin tüm noktalarına dağıtım hizmetini hızlı ve güvenli olarak sağlamaktadır.</p>
                            </div>
                        </li>
    
                        <li className="highlight lhgh" data-index="2">
                        <i className="flaticon-pantone effect-1"></i>							
                            <div className="fl-inner">
                                <h4>Güçlü Kadro</h4>
                                <p>Deneyimli personeli evrak, koli ve paketlerinizi titizlikle teslim etmektedir. İsteBiKurye hizmeti sunan firmamız, araçla ya da motorlu olarak kesintisiz teslimat sağlamaktadır.</p>
                            </div>
                        </li>
                        
                    </ul>
                </div>
              
                <div className="highlights-phone col-lg-4 col-md-4 col-sm-12 col-xs-12 wht">
                  
                    <div className="phone-holder">
                       <div id="fon">
                       <div className="hgh-linner hgi" data-index="0">
                            <img width="190" height="320" src={carousel_01} className="attachment-highlight wp-post-image" alt="screen" />    
                        </div>
                       </div>
                    
                        <div className="hgh-linner hgi" data-index="1">
                            <img width="234" height="398" src={carousel_02} className="attachment-highlight wp-post-image" alt="screensdst" />    
                        </div>
                        <div className="hgh-linner hgi" data-index="2">
                            <img width="234" height="398" src={carousel_03} className="attachment-highlight wp-post-image" alt="screen_09" />    
                        </div>
                        <div className="hgh-rinner hgi" data-index="3">
                            <img width="234" height="398" src={carousel_04} className="attachment-highlight wp-post-image" alt="screen_07" />    
                        </div>
                        <div className="hgh-rinner hgi" data-index="4">
                            <img width="234" height="398" src={carousel_01} className="attachment-highlight wp-post-image" alt="screen_08" />    
                        </div>
                        <div className="hgh-rinner hgi" data-index="5">
                            <img width="234" height="398" src={carousel_02} className="attachment-highlight wp-post-image" alt="screen_06" />    
                        </div>
                        <div className="hgh-rinner hgi" data-index="6">
                            <img width="234" height="398" src={carousel_03} className="attachment-highlight wp-post-image" alt="screen_06" />    
                        </div>
                        <div className="hgh-rinner hgi" data-index="7">
                            <img width="234" height="398" src={carousel_04} className="attachment-highlight wp-post-image" alt="screen_06" />    
                        </div>
                    </div>
                </div>
    
                
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 right-row">
                    <ul className="features-right" style={{textAlign:"left"}}>
                        <li className="highlight rhgh" data-index="4">
                        <i className="flaticon-pantone effect-1"></i>							 
                            <div className="fr-inner">
                                <h4>Güvenli  Hizmetler</h4>
                                <p>İsteBiKurye ile deneyimli ekibimiz ile gerçekleştirdiğimiz kurye hizmetlerimizden yararlanın. Bu aşamada hem kuryemizin hem de siparişlerinizin güvenliğini sağlamaktayız. Alınan bütün paketler artık bizim korumamız altında, bir emanet olarak elemanlarımıza verilmiştir.</p>
                            </div>
                        </li>
                        <li className="highlight rhgh" data-index="5">
                        <i className="flaticon-pantone effect-1"></i>	
                            <div className="fr-inner">
                                <h4>Istanbul/ Izmir</h4>
                                <p>İsteBiKurye hizmeti ile İstanbul ve Izmir içerisinde arzu ettiğiniz her türlü konuma paket vb. gönderilerinizi aynı gün içerisinde ulaştırmaktadır..</p>
                            </div>
                        </li>
                        <li className="highlight rhgh" data-index="6">
                        <i className="flaticon-pantone effect-1"></i>	
                            <div className="fr-inner">
                                <h4>Aktif Ekip</h4>
                                <p>Güler yüzlü ekibiyle, sizlere her daim hizmet sunabilmek adına 7/24 çalışma ilkesiyle hareket etmektedir. </p>
                            </div>
                        </li>
                      
                    </ul>
                </div>
              
            </div>
        </div>
    </div>

    )
}


export default AppFeatures













